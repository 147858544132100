<template>
  <div class="page_media" id="media_video">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="video_show">
            <video
              id="myVideo"
              :src="$fullUrl(url)"
              controls
            ></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/mixins/page.js";
export default {
  mixins: [mixin],
  data() {
    return {
        url: "/static/upload/movie.mp4",
    };
  },
  methods: {},
};
</script>

<style scoped>
.container{
	min-height: 750px;
}
#myVideo {
  width: 100%;
}
#media_video {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}
#media_video .video_show {
  width: 100%;
}
</style>
